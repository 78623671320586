




































import {Component, Vue} from 'vue-property-decorator';

@Component({
  components: {},
})
export default class WorkspacePage extends Vue {
  expandSecondary: boolean = false;

  openSideMenu() {
    this.$emit('sidemenu-toggle', true);
  }

  get hasSecondarySlot() {
    return !!this.$slots.secondary
  }
}
